<script>
    import Cashless from '@/services/Cashless'
    import modalViewTransactionDetails from  '@/components/modals/cashless/modalViewTransactionDetails';

    export default {
        components: { modalViewTransactionDetails },
        props: {
            serial: String
        },
        data() {
            return {
                tableCardTransactions: [],
                isBusy: false,
                totalRows: 1,
                currentPage: 1,
                perPage: 6,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "order_id",
                sortDesc: false,
                transactionData: [],
                fields: [
                    {
                        key: "transaction_id",
                        label: "Transaction ID",
                        sortable: true,
                    },
                    {
                        key: "summation_sign",
                        label: "Transaction Type",
                        sortable: true,
                    },
                    {
                        key: "name",
                        label: "Payment Type",
                        sortable: true,
                    },
                    {
                        key: "sub_type",
                        label: "Sub Type",
                        sortable: true,
                    },
                    {
                        key: "amount",
                        label: "Amount",
                        sortable: false,
                    },
                    {
                        key: "location_pos",
                        label: "Location Pos",
                        sortable: true,
                    },
                    {
                        key: "location_sector",
                        label: "Location Sector",
                        sortable: true,
                    },
                    {
                        key: "transaction_date",
                        label: "Date",
                        sortable: true,
                    },
                    "action"
                ]
            }
        },
        computed: {
            /**
             * Total no. of records
             */
            rows() {
                return this.tableCardTransactions.length;
            }
        },
        mounted() {
            this.getCardTransactions()
            this.totalRows = 0;
        },
        methods: {
            /**
             * Search the table data with search input
             */
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },

            async getCardTransactions(){
                this.toggleBusy();
                await Cashless.getCardTransactions(this.$route.params.id)
                    .then(response => {
                        this.tableCardTransactions = response.data.data
                    })
                    .catch(error => {
                        this.error = error.response.data.error ? error.response.data.error : "";
                    })
                    .finally(() => {
                        this.toggleBusy();
                    })
            },

            modalViewTransactionDetails(data){
                this.$bvModal.show("view_transaction_details");
                this.transactionData = data;
            },
        }
    }
</script>

<template>
    <div class="row">
        <div class="col-12">
            <div class="table-responsive mb-0">
                <b-table
                    :items="tableCardTransactions"
                    :busy="isBusy"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    show-empty
                    empty-text="No Data Found"
                >

                    <template v-slot:cell(action)="data">
                        <ul class="list-inline mb-0">
                            <li class="list-inline-item">
                                <a
                                        href="javascript:void(0);"
                                        @click="modalViewTransactionDetails(data.item)"
                                        class="px-2 text-primary"
                                        title="View Transaction Details"
                                >
                                    <i class="uil uil-eye font-size-18"></i>
                                </a>
                            </li>
                        </ul>
                    </template>

                    <template #table-busy>
                        <div class="text-center text-danger my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>Loading...</strong>
                        </div>
                    </template>
                </b-table>
            </div>
        </div>
        <div class="col-12">
            <div class="dataTables_paginate paging_simple_numbers float-end">
                <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                            v-model="currentPage"
                            :total-rows="rows"
                            :per-page="perPage"
                    ></b-pagination>
                </ul>
            </div>
        </div>


        <!-- MODALS -->
        <modalViewTransactionDetails :transactionData="this.transactionData"></modalViewTransactionDetails>
        <!-- /MODALS -->
    </div>
</template>
