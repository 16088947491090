
import Http from './Http'

export default {
    async getCards(data) {
        return await Http.get(`/cashless/cards?${data}`)
    },
    async getCustomerCards(data) {
        return await Http.get(`/cashless/customer/cards?${data}`)
    },
    async getCardDetails(serial) {
        return await Http.get(`/cashless/${serial}`)
    },
    async getCardTransactions(serial) {
        return await Http.get(`/cashless/cards/${serial}/transactions`)
    },
    async getCardTransactionDetails(serial, transaction_id) {
        return await Http.get(`/cashless/cards/${serial}/transactions/${transaction_id}`)
    },
    async getTopups(data) {
        return await Http.get(`/cashless/topups?${data}`)
    },
    async cardTopup(data) {
        return await Http.post(`/cashless/topup`, data)
    },
    async decreaseBalance(data) {
        return await Http.post(`/cashless/decreaseBalance`, data)
    },
    async activateCard(serial, data) {
        return await Http.patch(`/cashless/${serial}/activate`, data)
    },
    async deactivateCard(serial, data) {
        return await Http.patch(`/cashless/${serial}/deactivate`, data)
    },
    async associateCardToUser(serial, data) {
        return await Http.patch(`/cashless/attach/${serial}`, data)
    },
    async deassociateCardFromUser(serial, data) {
        return await Http.patch(`/cashless/detach/${serial}`, data)
    },
    async associateCardToSeason(data) {
        return await Http.patch(`/cashless/attach/season`, data)
    },
}


