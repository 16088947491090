<script>
    import Cashless from '@/services/Cashless';
    import Swal from "sweetalert2";

    export default {
        props: ['transactionData'],
        data() {
            return {
                showModal: false,
                transactionDetails:[],
                showLoader:false
            };
        },
        methods: {

            async getTransactionDetails(){
                this.showLoader = true;
                await Cashless.getCardTransactionDetails(this.transactionData.physical_cardnumber, this.transactionData.transaction_id).then((response) => {
                    if(response.data.data){
                        this.transactionDetails = response.data.data[0];
                    }

                }).catch(error => {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.failedmsg(this.error)
                }).finally(() => {
                    this.showLoader = false;
                })
            },

            closeModal() {
                this.showModal = false;
            },

            resetProps(){
                this.orderDetails = []
            },

            failedmsg(msg) {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: msg,
                    showConfirmButton: false,
                    timer: 5000,
                });
            },
        }
    };
</script>

<template>
    <b-modal @shown="getTransactionDetails" @hidden="resetProps" v-model="showModal" id="view_transaction_details" title="View Transaction Details" title-class="font-18" size="xl" centered>
        <template v-if="!showLoader">
            <div class="row mb-3" v-if="transactionDetails">

                <div class="col-sm-6">
                    <p class="font-size-16 mb-2"><span class="text-strong">Transaction ID:</span> {{transactionDetails.id}}</p>
                    <p class="font-size-16 mb-2"><span class="text-strong">Transaction Type:</span> {{transactionDetails.summation_sign}}</p>
                    <p class="font-size-16 mb-2"><span class="text-strong">Payment Type: </span>
                        <span v-if="transactionDetails.payments"> {{transactionDetails.payments[0].name}}</span>
                    </p>
                    <p class="font-size-16 mb-2"><span class="text-strong">Sub Type:</span>
                        <span v-if="transactionDetails.payments"> {{transactionDetails.payments[0].sub_type}}</span>
                    </p>
                    <p class="font-size-16 mb-2"><span class="text-strong">Completed: </span>
                        <span v-if="transactionDetails.completed">{{transactionDetails.completed}}</span>
                    </p>
                </div>
                <div class="col-sm-6">
                    <p class="font-size-16 mb-2"><span class="text-strong">Location Pos:</span> {{transactionDetails.location_pos}}</p>
                    <p class="font-size-16 mb-2"><span class="text-strong">Location Sector:</span> {{transactionDetails.location_sector}}</p>
                    <p class="font-size-16 mb-2"><span class="text-strong">Transaction Date:</span> {{transactionDetails.transaction_date}}</p>
                    <p class="font-size-16 mb-2"><span class="text-strong">Amount:</span> {{transactionDetails.amount}}</p>
                </div>

                <hr>

                <div class="row mt-3">
                    <div class="col-sm-12">
                        <h5>Transaction Details</h5>

                        <table class="table" v-if="transactionDetails.products">
                            <thead>
                            <th>Item ID</th>
                            <th>Name</th>
                            <th>Quantity</th>
                            <th>Price</th>
                            </thead>
                            <tbody>
                            <tr v-for="item in transactionDetails.products" :key="item.id">
                                <td>{{item.id}}</td>
                                <td>{{item.name}}</td>
                                <td>{{item.item_qty}}</td>
                                <td>{{item.item_price}}</td>
                            </tr>
                            </tbody>
                        </table>
                        <p class="text-center">No Data</p>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="card-body d-flex align-items-center justify-content-center" v-if="showLoader">
                <b-spinner large></b-spinner>
            </div>
        </template>


        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
        </template>
    </b-modal>
</template>