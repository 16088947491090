<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Cashless from '@/services/Cashless'
    import Ticketing from '@/services/Ticketing'
    import TabCardTransactions from "@/components/tabs/tabCardTransactions";
    import TabCustomerCards from "@/components/tabs/tabCustomerCards";
    import modalChangeCardBalance from "@/components/modals/cashless/modalChangeCardBalance";
    import modalChangeCustomertoCardAssociation from "@/components/modals/cashless/modalChangeCustomertoCardAssociation";
    import Swal from "sweetalert2";

    /**
     * Profile component
     */
    export default {
        components: {Layout,PageHeader,TabCardTransactions,TabCustomerCards,modalChangeCardBalance,modalChangeCustomertoCardAssociation},
        page: {
            title: "Card Details",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        data() {
            return {
                title: "Card Details",
                items: [
                    {
                        text: "Card Management",
                    },
                    {
                        text: "Card Details",
                        active: true,
                    },
                ],
                csrf_token: localStorage.getItem('csrf_token'),
                associateAction:'',
                error: null,
                tError: null,
                modalData: {},
                customer_id: '',
                toggleProfile: false,
                cardDetails:[],
                cardTransactions:[],
                showLoader:false
            };
        },
        async mounted() {
            this.getCard()
        },
        computed:{
            elDisabled(){
                return (this.cardDetails.status === "new") ? true : false;
            }
        },
        methods: {

            getCard(){
                this.showLoader = true;
                Cashless.getCardDetails(this.$route.params.id)
                    .then(response => {
                        if(response.data){
                            this.cardDetails = response.data
                        }
                    })
                    .catch(error => {
                        this.error = error.response.data.error ? error.response.data.error : "";
                    })
                    .finally(() => {
                        this.showLoader = false;
                    })
            },

            toggle() {
                this.toggleProfile = !this.toggleProfile
            },

            callModalChangeCardBalance(data) {
                this.$bvModal.show("card_balance_edit");
                this.modalData = data;
            },

            callmodalchangeCustomertoCardAssociation(data) {
                this.$bvModal.show("customer_to_card_association_change");
                this.modalData = data;
            },

            changeCardStatus(){
                var action = '';
                if(this.cardDetails.status === 'valid' || this.cardDetails.status === 'new' || this.cardDetails.status === 'active'){
                    action = 'disable';
                }else if(this.cardDetails.status === 'cancel'){
                    action = 'enable';
                }else{
                    return;
                }
                Swal.fire({
                    title: action === 'enable' ? "Are you sure you want to activate this card?" : "Are you sure you want to block this card?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor:  action === 'enable' ? "#34c38f" : "#f46a6a",
                    cancelButtonColor: action === 'enable' ? "#f46a6a" : "#34c38f" ,
                    confirmButtonText: action === 'enable' ? "Activate" : "Block",
                }).then((result) => {
                    if (result.value) {
                        try {
                            if(action === 'disable'){
                                Cashless.deactivateCard(this.cardDetails.serial,{
                                    serial:this.cardDetails.serial,
                                    customer_id:this.cardDetails.customer[0].customer_id
                                }).then((response) => {
                                    const res = response.data ? response.data : false;
                                    const error = response.data.error ? response.data.error : 'Failed';
                                    if(res){
                                        this.getCard()
                                        Swal.fire("Card blocked", "Card is successfully blocked!", "success");
                                    }else{
                                        Swal.fire("Fail!", error, "warning");
                                    }
                                })
                            }else if(action === 'enable'){
                                Cashless.activateCard(this.cardDetails.serial, {
                                    serial:this.cardDetails.serial,
                                    customer_id:this.cardDetails.customer[0].customer_id
                                }).then((response) => {
                                    const res = response.data ? response.data : false;
                                    const error = response.data.error ? response.data.error : 'Failed';
                                    if(res){
                                        this.getCard()
                                        Swal.fire("Card Activated", "Card is successfully activated!", "success");
                                    }else{
                                        Swal.fire("Fail!", error, "warning");
                                    }
                                })
                            }else{
                                this.failedmsg('Card status can not be changed')
                            }

                        }catch(error){
                            this.error = error.response.data.error ? error.response.data.error : "";
                        }
                    }
                });
            },

            deassociateSeasonTicket(){

                const swalOptions = {
                    title: "Are you sure you want to deassociate this season ticket from the customer?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Yes",
                };

                var checkboxShown = false;

                if(this.cardDetails.customer && this.cardDetails.customer.length){
                    swalOptions.input = 'checkbox';
                    swalOptions.inputValue = true;
                    swalOptions.inputPlaceholder = 'Remove Associated User';
                    checkboxShown = true
                }

                Swal.fire(swalOptions).then((result) => {

                    if (result.isConfirmed) {
                        const removeAssociatedUser = result.value;

                        this.showLoader = true
                        Ticketing.deassociateSeasonTicket({
                            serial: this.cardDetails.serial,
                            subscription_number: this.cardDetails.season_ticket[0].subscription_number
                        })
                            .then((response) => {
                                const res   = response.data.data  ? response.data.data  : false;
                                const error = response.data.error ? response.data.error : '';
                                if(res && error==''){
                                    if(checkboxShown && removeAssociatedUser){
                                        Cashless.deassociateCardFromUser(this.cardDetails.serial, {
                                            serial: this.cardDetails.serial,
                                            customer_id: this.cardDetails.customer[0].customer_id,
                                            csrf_token: this.csrf_token
                                        }).then((response) => {
                                            this.successmsg("Successfully deassociated Season Ticket and Customer from card")
                                        }).catch(error => {
                                            this.error = error.response.data.error ? error.response.data.error : "";
                                            this.failedmsg(this.error)
                                        }).finally(() => {
                                            this.getCard()
                                            this.showLoader = false
                                        })
                                    }else{
                                        this.successmsg("Season Ticket successfully deassociated")
                                        this.getCard()
                                        this.showLoader = false
                                    }
                                }else{
                                    this.failedmsg(error)
                                    this.getCard()
                                    this.showLoader = false
                                }
                            })
                            .catch(error => {
                                this.failedmsg(error)
                            })

                    }
                });
            },

            cardStatusTitle(){
                if(this.cardDetails.status === 'valid' || this.cardDetails.status === 'active'){
                    return 'Block Card';
                }else if(this.cardDetails.status === 'cancel'){
                    return 'Activate Card';
                }else{
                    return 'Activate Card';
                }
            },
            cardAssociationTitle(){
                if(this.cardDetails.customer && this.cardDetails.customer.length){
                    this.associateAction = 'deassociate'
                    return 'Deassociate Card';
                }else if(this.cardDetails.customer && !this.cardDetails.customer.length){
                    this.associateAction = 'associate'
                    return 'Associate Card';
                }else{
                    return 'Associate Card';
                }
            },

            failedmsg(msg) {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: msg,
                    showConfirmButton: false,
                    timer: 5000,
                });
            },
        },
    };
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row mb-4" v-if="!error">
            <div v-if="!toggleProfile" class="col-xl-4">
                <div class="card h-100">

                    <div class="card-body" v-if="!showLoader">
                        <div class="text-center ">
                            <b-dropdown class="float-end" variant="white" right menu-class="dropdown-menu-end" toggle-class="font-size-16 text-body p-0">
                                <template v-slot:button-content>
                                    <i class="uil uil-ellipsis-v"></i>
                                </template>
                                <a class="dropdown-item" href="javascript:void(0);" @click="callModalChangeCardBalance(cardDetails)">Change Card Balance</a>
                                <a class="dropdown-item" :class="{'text-muted':elDisabled }" href="javascript:void(0);" :disabled='elDisabled' @click="!elDisabled ? changeCardStatus():''">{{cardStatusTitle()}}</a>
                                <a class="dropdown-item" href="javascript:void(0);" @click="callmodalchangeCustomertoCardAssociation(cardDetails)">{{cardAssociationTitle()}}</a>
                                <a class="dropdown-item" href="javascript:void(0);" v-if="cardDetails.season_ticket && cardDetails.season_ticket.length" @click="deassociateSeasonTicket(cardDetails)">Deassociate Season Ticket</a>
                            </b-dropdown>

                            <i class="uil uil-angle-left float-start font-size-20 text-body btn p-0" @click="toggle"></i>
                            <div class="clearfix"></div>
                            <h3 class="mt-3 mb-1 ">{{ cardDetails.serial }}</h3>
                            <p class="font-size-18" v-html="format_status(cardDetails.status)"></p>

                            <div class="mt-4">

                            </div>
                        </div>

                        <hr class="my-2" />

                        <div class="table-responsive mt-4 mb-0">
                            <div v-if="cardDetails.season_ticket && cardDetails.season_ticket.length">
                                <h5>Season Ticket</h5>
                                <div v-for="seasonTicket in cardDetails.season_ticket" :key="seasonTicket.subscription_number">
                                    <div class="mt-3" >
                                        <p class="mb-2 text-strong">Subscription Number :
                                            <span class="text-muted float-end" >
                                            {{seasonTicket.subscription_number}}
                                        </span>
                                        </p>
                                    </div>
                                </div>

                                <b-button class="mb-2 mt-4 d-block m-auto mwb-11" variant="primary" title="Deassociate Season Ticket" @click="deassociateSeasonTicket(cardDetails)">Deassociate Season Ticket</b-button>

                                <hr>
                            </div>
                            <h5>Card Details</h5>
                            <div class="mt-3">
                                <p class="mb-2 text-strong">Customer :
                                    <span class="text-muted float-end" v-if="cardDetails.customer && cardDetails.customer.length">
                                        <router-link :to="{ path: `/customers/profile/${cardDetails.customer[0].customer_id}`}">
                                            {{cardDetails.customer[0].first_name}} {{cardDetails.customer[0].last_name}}
                                        </router-link>
                                    </span>
                                    <span class="text-muted float-end" v-else>/</span>
                                </p>
                            </div>
                            <div class="mt-3">
                                <p class="mb-2 text-strong">RFID :
                                    <span class="text-muted float-end" >{{cardDetails.rfid}}</span>
                                </p>
                            </div>
                            <div class="mt-3">
                                <p class="mb-2 text-strong">Series :
                                    <span class="text-muted float-end" >{{cardDetails.series}}</span>
                                </p>
                            </div>
                            <div class="mt-3">
                                <p class="mb-2 text-strong">CVC :
                                    <span class="text-muted float-end" >{{cardDetails.cvc}}</span>
                                </p>
                            </div>
                            <div class="mt-3">
                                <p class="mb-2 text-strong">Not for Sale :
                                    <span class="text-muted float-end" >{{cardDetails.no_sale ? true : false}}</span>
                                </p>
                            </div>
                            <div class="mt-3">
                                <p class="mb-2 text-strong">Discount (%) :
                                    <span class="text-muted float-end" >{{cardDetails.discount_percentage}} %</span>
                                </p>
                            </div>
                            <div class="mt-3">
                                <p class="mb-2 text-strong">Balance :
                                    <span class="text-muted float-end" >{{cardDetails.balance}}</span>
                                </p>
                            </div>
                            <div class="mt-3">
                                <p class="mb-2 text-strong">Currency :
                                    <span class="text-muted float-end" >{{cardDetails.currency}}</span>
                                </p>
                            </div>
                            <div class="mt-3">
                                <p class="mb-2 text-strong">Account ID :
                                    <span class="text-muted float-end" >{{cardDetails.account_id}}</span>
                                </p>
                            </div>

                            <hr>

                            <b-button class="mb-2 d-block m-auto mwb-11" variant="primary" title="Change Card Balance" @click="callModalChangeCardBalance(cardDetails)"><i class="mdi mdi-account-cog"></i> Change Card Balance</b-button>
                            <b-button class="mb-2 d-block m-auto mwb-11" variant="primary" :disabled='elDisabled' :title="cardStatusTitle()" @click="changeCardStatus()"><i class="mdi mdi-credit-card-sync-outline"></i> {{cardStatusTitle()}}</b-button>
                            <b-button class="mb-2 d-block m-auto mwb-11" variant="primary" :title="cardAssociationTitle()" @click="callmodalchangeCustomertoCardAssociation(cardDetails)"><i class="mdi mdi-account-cash-outline"></i> {{cardAssociationTitle()}}</b-button>

                        </div>

                    </div>
                    <div class="card-body d-flex align-items-center justify-content-center" v-else>
                        <b-spinner large></b-spinner>
                    </div>
                </div>
            </div>
            <div v-else class="col-xl-2">
                <div class="card h-100">
                    <div class="card-body">
                        <div class="text-center">
                            <b-dropdown class="float-end" variant="white" right menu-class="dropdown-menu-end" toggle-class="font-size-16 text-body p-0">
                                <template v-slot:button-content>
                                    <i class="uil uil-ellipsis-v"></i>
                                </template>
                                <a class="dropdown-item" href="javascript:void(0);" @click="callmodalchangeCustomertoCardAssociation(cardDetails)">{{cardAssociationTitle()}}</a>
                                <a class="dropdown-item" :class="{'text-muted':elDisabled }" href="javascript:void(0);" :disabled='elDisabled' @click="changeCardStatus()">{{cardStatusTitle()}}</a>
                                <a class="dropdown-item" href="javascript:void(0);" @click="callModalChangeCardBalance(cardDetails)">Change Card Balance</a>
                                <a class="dropdown-item" href="javascript:void(0);" v-if="cardDetails.season_ticket && cardDetails.season_ticket.length" @click="deassociateSeasonTicket(cardDetails)">Deassociate Season Ticket</a>

                            </b-dropdown>
                            <i class="uil uil-angle-right float-start font-size-20 text-body btn p-0" @click="toggle"></i>
                            <div class="clearfix"></div>
                            <h3 class="mt-3 mb-1 ">{{ cardDetails.serial }}</h3>
                            <p class="font-size-18" v-html="format_status(cardDetails.status)"></p>

                            <div v-if="cardDetails.season_ticket && cardDetails.season_ticket.length">
                                <h5 class="mt-3">Season Ticket</h5>
                                <div v-for="seasonTicket in cardDetails.season_ticket" :key="seasonTicket.subscription_number">
                                    <p class="mb-2 text-strong">Subscription Number :
                                        <span class="text-muted float-end" >
                                        {{seasonTicket.subscription_number}}
                                    </span>
                                    </p>
                                </div>

                                <hr>
                            </div>

                            <div class="mt-3" v-if="cardDetails.customer && cardDetails.customer.length">
                                <h5 class="mt-3">Customer</h5>
                                <h6 class="text-muted " >
                                    <router-link :to="{ path: `/customers/profile/${cardDetails.customer[0].customer_id}`}">
                                        {{cardDetails.customer[0].first_name}} {{cardDetails.customer[0].last_name}}
                                    </router-link>
                                </h6>
                            </div>

                            <div class="mt-4">
                                <b-button class="mb-2 d-block m-auto mwb-11" variant="primary" title="Change Card Balance" @click="callModalChangeCardBalance(cardDetails)">Change Card Balance</b-button>
                                <b-button class="mb-2 d-block m-auto mwb-11" :disabled='elDisabled' variant="primary" :title="cardStatusTitle()" @click="changeCardStatus()">{{cardStatusTitle()}}</b-button>
                                <b-button class="mb-2 d-block m-auto mwb-11" variant="primary" :title="cardAssociationTitle()" @click="callmodalchangeCustomertoCardAssociation(cardDetails)">{{cardAssociationTitle()}}</b-button>
                                <b-button class="mb-2 d-block m-auto mwb-11" variant="primary" title="Deassociate Season Ticket" v-if="cardDetails.season_ticket && cardDetails.season_ticket.length" @click="deassociateSeasonTicket(cardDetails)" >Deassociate Season Ticket</b-button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div :class="!toggleProfile ? 'col-xl-8':'col-xl-10'">
                <div class="card mb-0">
                    <b-tabs content-class="p-4" justified class="nav-tabs-custom">
                        <b-tab active>
                            <template v-slot:title>
                                <i class="uil uil-exchange font-size-20"></i>
                                <span class="d-none d-sm-block">Transactions</span>
                            </template>
                            <div class="row">
                                <TabCardTransactions :serial="this.$route.params.id" />
                            </div>
                        </b-tab>
                        <b-tab>
                            <template v-slot:title>
                                <i class="uil uil-credit-card font-size-20"></i>
                                <span class="d-sm-block" >Other Customer Cards</span>
                            </template>
                            <div class="row" v-if="cardDetails.customer && cardDetails.customer.length">
                                <TabCustomerCards :customer="this.cardDetails.customer[0].customer_id" :serial="this.$route.params.id" />
                            </div>
                            <div class="row text-center" v-else><div class="col-md-12">No Data Found</div></div>
                        </b-tab>

                    </b-tabs>
                </div>
            </div>
        </div>

        <div class="row mb-4" v-else>
            <div class="card h-100">
                <div class="card-body">
                    <div class="text-center">
                        {{error}}
                    </div>
                </div>
            </div>
        </div>

        <!-- end row -->
        <modalChangeCardBalance :cardData="modalData" @onRefresh="getCard()"></modalChangeCardBalance>
        <modalChangeCustomertoCardAssociation :associateAction="associateAction" :cardData="modalData" @onRefresh="getCard()"></modalChangeCustomertoCardAssociation>

    </Layout>
</template>
