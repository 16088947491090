<script>
    import {required, numeric, maxValue, decimal} from "vuelidate/lib/validators";
    import Cashless from '@/services/Cashless';
    import Swal from "sweetalert2";
    import validationMessages from '@/components/validations'

    export default {
        components:{
            validationMessages
        },
        props: {
            cardData: {
                type: Object
            },
        },
        data() {
            return {
                csrf_token: localStorage.getItem('csrf_token'),
                amount:'',
                changeType: 'Increase',
                changeTypeOptions: [
                    { item: 'Increase', name: 'Add Amount' },
                    { item: 'Decrease', name: 'Remove Amount' },
                ],
                submitted: false,
                showModal: false,
                tryingToEdit: false,

            };
        },
        validations: {
            amount: {
                required,
                decimal,
                maxValue: maxValue(100000),
            },
        },
        created() {

        },
        methods: {

            async changeCardBalance(){
                this.tryingToEdit = true;
                this.submitted = true;
                this.$v.$touch()

                if (this.$v.$invalid) {
                    this.tryingToEdit = false;
                    return;
                } else {
                    try {


                        if(this.changeType == 'Increase'){
                            var customer_id = null;
                            if(this.cardData.customer.length > 0){
                                customer_id = this.cardData.customer[0].customer_id;
                            }
                            await Cashless.cardTopup({
                                amount:this.amount,
                                action:this.changeType,
                                serial:this.cardData.serial,
                                customer_id:customer_id,
                                csrf_token: this.csrf_token
                            }).then((response) => {
                                this.successmsg(response);
                                this.closeModal();
                            }).catch(error => {
                                this.error = error.response.data.error ? error.response.data.error : "";
                                this.failedmsg(this.error)
                            }).finally(() => {
                                this.refreshData()
                                this.tryingToEdit = false;
                            })
                        }else if(this.changeType == 'Decrease'){

                            await Cashless.decreaseBalance({
                                amount:this.amount,
                                action:this.changeType,
                                serial:this.cardData.serial,
                                customer_id:this.cardData.customer[0].customer_id,
                                csrf_token: this.csrf_token
                            }).then((response) => {
                                this.successmsg(response);
                                this.closeModal();
                            }).catch(error => {
                                this.error = error.response.data.error ? error.response.data.error : "";
                                this.failedmsg(this.error)
                            }).finally(() => {
                                this.refreshData()
                                this.tryingToEdit = false;
                            })
                        }else{
                            this.failedmsg('Select available card balance action')
                        }

                    } catch (error) {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg(this.error)
                    }
                }
                this.tryingToEdit = false;
                this.submitted = false
            },


            refreshData() {
                this.$emit('onRefresh') //event from parent
            },

            closeModal() {
                this.showModal = false;
            },

            successmsg() {
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Change has been saved",
                    showConfirmButton: false,
                    timer: 2000,
                });
            },

            failedmsg(msg) {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: msg,
                    showConfirmButton: false,
                    timer: 5000,
                });
            },

            resetProps(){
                this.amount = '';
                this.changeType = 'Increase';
            }
        }
    };
</script>

<template>
    <b-modal v-model="showModal" @hidden="resetProps" id="card_balance_edit" title="Change Card Balance" title-class="font-18" centered>
        <form @submit.prevent="changeCardBalance">

            <b-form-radio-group
                    v-model="changeType"
                    :options="changeTypeOptions"
                    class="mb-3"
                    value-field="item"
                    text-field="name"
            ></b-form-radio-group>

            <b-form-group label="Amount" label-for="formrow-amount-input" class="mb-3">
                <b-form-input
                        v-model.trim="amount"
                        type="number"
                        id="amount"
                        aria-describedby="amount-feedback"
                        :class="{
                            'is-invalid': submitted && $v.amount.$error,
                        }"
                >
                </b-form-input>
                <validationMessages v-if="submitted" :fieldName="'Amount'" :validationName="$v.amount"></validationMessages>
            </b-form-group>
            <input type="hidden" name="csrf_token" v-model="csrf_token">
        </form>

        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="changeCardBalance" :disabled="tryingToEdit">
                <b-spinner v-show="tryingToEdit" small></b-spinner>
                Submit
            </b-button>
        </template>
    </b-modal>
</template>