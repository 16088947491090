<script>
    import Cashless from '@/services/Cashless'


    export default {
        components: {  },
        props: {
            customer: String,
            serial: String
        },
        data() {
            return {
                tableCustomerCards: [],
                isBusy: false,
                totalRows: 1,
                currentPage: 1,
                perPage: 6,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "serial",
                sortDesc: false,
                guid: '',
                fields: [
                    {
                        key: "serial",
                        label: "Card Serial",
                        sortable: true,
                    },
                    {
                        key: "series",
                        label: "Card Series",
                        sortable: true,
                    },
                    {
                        key: "rfid",
                        label: "RFID",
                        sortable: true,
                    },
                    {
                        key: "status",
                        label: "Status",
                        sortable: true,
                    },
                    "action",
                ]
            }
        },
        computed: {
            /**
             * Total no. of records
             */
            rows() {
                return this.tableCustomerCards.length;
            }
        },
        mounted() {
            this.getCardTransactions()
            this.totalRows = 0;
        },
        methods: {
            /**
             * Search the table data with search input
             */
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },

            async getCardTransactions(){
                this.toggleBusy();
                await Cashless.getCustomerCards('customer_id='+this.customer)
                    .then(response => {
                        let tempData = response.data.data
                        tempData = tempData.filter((item) => {
                            return (item.serial !== this.serial)
                        })

                        this.tableCustomerCards = tempData;
                    })
                    .catch(error => {
                        this.error = error.response.data.error ? error.response.data.error : "";
                    })
                    .finally(() => {
                        this.toggleBusy();
                    })
            },
        }
    }
</script>

<template>
    <div class="row">
        <div class="col-12">
            <div class="table-responsive mb-0">
                <b-table
                    :items="tableCustomerCards"
                    :busy="isBusy"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    show-empty
                    empty-text="No Data Found"
                >

                    <template v-slot:cell(action)="data">
                        <ul class="list-inline mb-0">
                            <li class="list-inline-item">
                                <router-link :to="{ path: `/cashless/card-details/${data.item.serial}`}">
                                    <i class="uil uil-eye font-size-18"></i>
                                </router-link>
                            </li>
                        </ul>
                    </template>

                    <template #table-busy>
                        <div class="text-center text-danger my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>Loading...</strong>
                        </div>
                    </template>
                </b-table>
            </div>
        </div>
        <div class="col-12">
            <div class="dataTables_paginate paging_simple_numbers float-end">
                <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                            v-model="currentPage"
                            :total-rows="rows"
                            :per-page="perPage"
                    ></b-pagination>
                </ul>
            </div>
        </div>

    </div>
</template>
